import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5345471a"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "content"
};
var _hoisted_2 = {
  class: "sidebar"
};
var _hoisted_3 = {
  class: "map"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_hotels_map_card = _resolveComponent("hotels-map-card");

  var _component_g_map_map = _resolveComponent("g-map-map");

  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_popup = _resolveComponent("popup");

  var _component_van_config_provider = _resolveComponent("van-config-provider");

  return _openBlock(), _createBlock(_component_van_config_provider, {
    "theme-vars": _ctx.themeVars
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_popup, {
        class: "hotels-map-popup",
        show: _ctx.open,
        round: "",
        style: {
          overflow: 'revert'
        }
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createElementVNode("section", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hotels, function (hotel, index) {
            return _openBlock(), _createBlock(_component_hotels_map_card, {
              hotel: hotel,
              key: index,
              activeHotelId: _ctx.activeHotelId,
              handleHotelActive: _ctx.handleHotelActive
            }, null, 8, ["hotel", "activeHotelId", "handleHotelActive"]);
          }), 128))]), _createElementVNode("section", _hoisted_3, [_createVNode(_component_g_map_map, {
            id: "map",
            ref: "gmapRef",
            center: _ctx.getCityLocation,
            zoom: 13,
            "map-type": "roadmap",
            options: {
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: true,
              streetViewControl: false,
              rotateControl: true,
              fullscreenControl: false,
              gestureHandling: 'greedy'
            },
            style: {
              "width": "100%",
              "height": "100%"
            }
          }, null, 8, ["center"])])]), _createVNode(_component_svg_icon, {
            class: "close-icon",
            iconName: "icon_48_close_big",
            onClick: _cache[0] || (_cache[0] = function () {
              return _ctx.handleOpenMap(false);
            })
          })];
        }),
        _: 1
      }, 8, ["show"])];
    }),
    _: 1
  }, 8, ["theme-vars"]);
}