import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/slider/style";
import _Slider from "vant/es/slider";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import { computed, defineComponent, inject, watch } from 'vue';
import { useHotelOption } from '@/hook/useHotelOption';
import { useFilterTags } from '@/hook/useFilterTags';
import { useSearchHotels } from '@/hook/useSearchHotels';
export default defineComponent({
  components: _defineProperty({}, _Slider.name, _Slider),
  setup: function setup() {
    var t = inject('t');

    var _useFilterTags = useFilterTags(),
        roomFacilities = _useFilterTags.roomFacilities,
        hotelFacilities = _useFilterTags.hotelFacilities,
        range = _useFilterTags.range,
        popular = _useFilterTags.popular,
        onClearRange = _useFilterTags.onClearRange,
        onClearRoomFacilities = _useFilterTags.onClearRoomFacilities,
        onClearHotelFacilities = _useFilterTags.onClearHotelFacilities,
        onClearPopular = _useFilterTags.onClearPopular;

    var _useHotelOption = useHotelOption(),
        hotelOptionData = _useHotelOption.hotelOptionData;

    var _useSearchHotels = useSearchHotels(),
        handleGoHotels = _useSearchHotels.handleGoHotels;

    var onChange = function onChange() {
      handleGoHotels();
    };

    var showPopularFilter = computed(function () {
      var list = hotelOptionData.value.popular_filter || [];
      return list.length > 0;
    });
    watch(roomFacilities, function (newD, oldD) {
      if (Object.keys(newD).length !== Object.keys(oldD).length) {
        onChange();
      }
    });
    watch(hotelFacilities, function (newD, oldD) {
      if (Object.keys(newD).length !== Object.keys(oldD).length) {
        onChange();
      }
    });
    watch(popular, function (newD, oldD) {
      if (Object.keys(newD).length !== Object.keys(oldD).length) {
        onChange();
      }
    });
    return {
      t: t,
      hotelOptionData: hotelOptionData,
      range: range,
      popular: popular,
      roomFacilities: roomFacilities,
      hotelFacilities: hotelFacilities,
      onClearRange: onClearRange,
      onClearRoomFacilities: onClearRoomFacilities,
      onClearHotelFacilities: onClearHotelFacilities,
      onClearPopular: onClearPopular,
      onChange: onChange,
      showPopularFilter: showPopularFilter
    };
  }
});