import { defineComponent, inject } from 'vue';
import { numberWithCommas } from '@/utils/numberWithCommas';
import { useSearchHotels } from '@/hook/useSearchHotels';
export default defineComponent({
  name: 'HotelsMapCard',
  components: {},
  props: ['hotel', 'activeHotelId', 'handleHotelActive'],
  setup: function setup(props) {
    var t = inject('t');

    var _useSearchHotels = useSearchHotels(),
        handleGoHotel = _useSearchHotels.handleGoHotel;

    var hoverHotelCard = function hoverHotelCard(id) {
      props.handleHotelActive(id);
    };

    var redirectToHotelPage = function redirectToHotelPage(id) {
      handleGoHotel(id);
    };

    return {
      t: t,
      numberWithCommas: numberWithCommas,
      redirectToHotelPage: redirectToHotelPage,
      hoverHotelCard: hoverHotelCard
    };
  }
});