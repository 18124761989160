import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7e86c3bf"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "content"
};
var _hoisted_2 = {
  key: 0,
  class: "sidebar"
};
var _hoisted_3 = {
  class: "sidebar-map"
};
var _hoisted_4 = {
  class: "sidebar-title"
};
var _hoisted_5 = {
  class: "body-section"
};
var _hoisted_6 = {
  key: 3,
  class: "hotel-cards"
};
var _hoisted_7 = {
  key: 4,
  class: "other-recommend-title"
};
var _hoisted_8 = {
  key: 5,
  class: "hotel-cards"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$hotelOptionData, _ctx$hotelOptionData2;

  var _component_header_component = _resolveComponent("header-component");

  var _component_pc_search = _resolveComponent("pc-search");

  var _component_mobile_search = _resolveComponent("mobile-search");

  var _component_breadcrumb = _resolveComponent("breadcrumb");

  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_filter_content = _resolveComponent("filter-content");

  var _component_action_bar_mobile = _resolveComponent("action-bar-mobile");

  var _component_sorting_bar = _resolveComponent("sorting-bar");

  var _component_no_result = _resolveComponent("no-result");

  var _component_hotel_card = _resolveComponent("hotel-card");

  var _component_van_list = _resolveComponent("van-list");

  var _component_footer_component = _resolveComponent("footer-component");

  var _component_hotels_map_popup = _resolveComponent("hotels-map-popup");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_header_component), _createElementVNode("main", null, [!_ctx.isMobile ? (_openBlock(), _createBlock(_component_pc_search, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_mobile_search, {
    key: 1
  })), _createVNode(_component_breadcrumb, {
    class: "pc-only"
  }), _createElementVNode("div", _hoisted_1, [!_ctx.isMobile ? (_openBlock(), _createElementBlock("aside", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_svg_icon, {
    class: "map-pin",
    iconName: "map_pin"
  }), _createElementVNode("button", {
    class: "map-btn",
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.handleOpenMap(true);
    })
  }, _toDisplayString(_ctx.t('search.view_map')), 1)]), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('search.filter_search')) + "：", 1), _createVNode(_component_filter_content)])) : _createCommentVNode("", true), _createElementVNode("section", _hoisted_5, [_ctx.isMobile ? (_openBlock(), _createBlock(_component_action_bar_mobile, {
    key: 0,
    sortBy: _ctx.sortBy,
    sortingItems: (_ctx$hotelOptionData = _ctx.hotelOptionData) === null || _ctx$hotelOptionData === void 0 ? void 0 : _ctx$hotelOptionData.sorting,
    changeSortBy: _ctx.changeSortBy
  }, null, 8, ["sortBy", "sortingItems", "changeSortBy"])) : _createCommentVNode("", true), !_ctx.isMobile ? (_openBlock(), _createBlock(_component_sorting_bar, {
    key: 1,
    sortBy: _ctx.sortBy,
    sortingItems: (_ctx$hotelOptionData2 = _ctx.hotelOptionData) === null || _ctx$hotelOptionData2 === void 0 ? void 0 : _ctx$hotelOptionData2.sorting,
    changeSortBy: _ctx.changeSortBy
  }, null, 8, ["sortBy", "sortingItems", "changeSortBy"])) : _createCommentVNode("", true), _ctx.hotels.length === 0 ? (_openBlock(), _createBlock(_component_no_result, {
    key: 2
  })) : _createCommentVNode("", true), _ctx.hotels.length > 0 ? (_openBlock(), _createElementBlock("section", _hoisted_6, [_createVNode(_component_van_list, {
    loading: _ctx.hotelsLoading,
    "onUpdate:loading": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.hotelsLoading = $event;
    }),
    finished: _ctx.isFinished,
    "finished-text": "",
    "loading-text": _ctx.t('loading.loading_please_wait'),
    onLoad: _ctx.onLoad
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hotels, function (hotel, idx) {
        return _openBlock(), _createBlock(_component_hotel_card, {
          key: idx,
          hotel: hotel
        }, null, 8, ["hotel"]);
      }), 128))];
    }),
    _: 1
  }, 8, ["loading", "finished", "loading-text", "onLoad"])])) : _createCommentVNode("", true), _ctx.hotels.length === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.t('search.other_recommendations')), 1)) : _createCommentVNode("", true), _ctx.hotels.length === 0 ? (_openBlock(), _createElementBlock("section", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recommend, function (hotel, idx) {
    return _openBlock(), _createBlock(_component_hotel_card, {
      key: idx,
      hotel: hotel
    }, null, 8, ["hotel"]);
  }), 128))])) : _createCommentVNode("", true)])])]), _createVNode(_component_footer_component), _createVNode(_component_hotels_map_popup, {
    open: _ctx.isMapOpen,
    handleOpenMap: _ctx.handleOpenMap
  }, null, 8, ["open", "handleOpenMap"])], 64);
}