import "core-js/modules/es.array.sort.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-cb0013de"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "action-bar"
};
var _hoisted_2 = {
  class: "icon"
};
var _hoisted_3 = {
  key: 0,
  class: "counts"
};
var _hoisted_4 = {
  class: "icon"
};
var _hoisted_5 = {
  class: "icon"
};
var _hoisted_6 = {
  class: "filter-content-section"
};
var _hoisted_7 = {
  class: "button-group"
};
var _hoisted_8 = {
  class: "icon-place"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_filter_content = _resolveComponent("filter-content");

  var _component_page = _resolveComponent("page");

  var _component_popup = _resolveComponent("popup");

  var _component_nav_bar = _resolveComponent("nav-bar");

  var _component_list_item = _resolveComponent("list-item");

  var _component_list_menu = _resolveComponent("list-menu");

  var _component_hotels_map_popup = _resolveComponent("hotels-map-popup");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("section", _hoisted_1, [_createElementVNode("button", {
    class: "action-btn",
    onClick: _cache[0] || (_cache[0] = //@ts-ignore
    function () {
      return _ctx.openFilterModal && _ctx.openFilterModal.apply(_ctx, arguments);
    })
  }, [_createElementVNode("i", _hoisted_2, [_createVNode(_component_svg_icon, {
    class: "svg-icon",
    iconName: "filter-filter"
  })]), _ctx.filterCounts ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.filterCounts), 1)) : _createCommentVNode("", true)]), _createElementVNode("button", {
    class: "action-btn",
    onClick: _cache[1] || (_cache[1] = //@ts-ignore
    function () {
      return _ctx.openSortingModal && _ctx.openSortingModal.apply(_ctx, arguments);
    })
  }, [_createElementVNode("i", _hoisted_4, [_createVNode(_component_svg_icon, {
    class: "svg-icon",
    iconName: "filter-sort"
  })])]), _createElementVNode("button", {
    class: "action-btn",
    onClick: _cache[2] || (_cache[2] = function () {
      return _ctx.handleOpenMap(true);
    })
  }, [_createElementVNode("i", _hoisted_5, [_createVNode(_component_svg_icon, {
    class: "svg-icon",
    iconName: "filter-location"
  })])])]), _createVNode(_component_popup, {
    show: _ctx.isFilterModalOpen,
    position: "right"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_page, {
        class: "filter-content-popup",
        onClose: _ctx.closeFilterModal,
        title: _ctx.t('search.filters')
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("section", _hoisted_6, [_createVNode(_component_filter_content)]), _createElementVNode("section", _hoisted_7, [_createElementVNode("button", {
            class: "btn filter",
            onClick: _cache[3] || (_cache[3] = function ($event) {
              return _ctx.closeFilterModal();
            })
          }, _toDisplayString(_ctx.t('search.filter')), 1), _createElementVNode("button", {
            class: "btn clear",
            onClick: _cache[4] || (_cache[4] = function ($event) {
              return _ctx.handleAllClear();
            })
          }, _toDisplayString(_ctx.t('search.clear')), 1)])];
        }),
        _: 1
      }, 8, ["onClose", "title"])];
    }),
    _: 1
  }, 8, ["show"]), _createVNode(_component_popup, {
    show: _ctx.isSortingModalOpen,
    position: "bottom",
    round: ""
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_nav_bar, {
        onClose: _ctx.closeSortingModal
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.t('search.sorting')), 1)];
        }),
        _: 1
      }, 8, ["onClose"]), _createVNode(_component_list_menu, null, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortingItems, function (item) {
            return _openBlock(), _createBlock(_component_list_item, {
              key: item.sort,
              onClick: function onClick() {
                return _ctx.handleSortBy(item.sort);
              }
            }, {
              default: _withCtx(function () {
                return [_createElementVNode("div", _hoisted_8, [_ctx.sortBy === item.sort ? (_openBlock(), _createBlock(_component_svg_icon, {
                  key: 0,
                  class: "checked-icon",
                  iconName: "icon_24_dropdownmenu_check"
                })) : _createCommentVNode("", true)]), _createTextVNode(" " + _toDisplayString(_ctx.t("search.sorting.".concat(item.sort))), 1)];
              }),
              _: 2
            }, 1032, ["onClick"]);
          }), 128))];
        }),
        _: 1
      })];
    }),
    _: 1
  }, 8, ["show"]), _createVNode(_component_hotels_map_popup, {
    open: _ctx.isMapOpen,
    handleOpenMap: _ctx.handleOpenMap
  }, null, 8, ["open", "handleOpenMap"])], 64);
}