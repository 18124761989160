import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/list/style";
import _List from "vant/es/list";

var _components;

import "core-js/modules/es.function.name.js";
import { defineComponent, computed, onMounted, ref, watch, inject } from 'vue';
import { useStore } from 'vuex';
import { useHotelOption } from '@/hook/useHotelOption';
import Header from '@/components/Header.vue'; // @ is an alias to /src

import MobileSearch from '@/components/mobile/MobileSearch.vue';
import PcSearch from '@/components/hotels/PcSearch.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import FilterContent from '@/components/hotels/FilterContent.vue';
import ActionBarMobile from '@/components/mobile/ActionBarMobile.vue';
import SortingBar from '@/components/hotels/SortingBar.vue';
import HotelCard from '@/components/hotels/HotelCard.vue';
import NoResult from '@/components/hotels/NoResult.vue';
import HotelsMapPopup from '@/components/hotels/HotelsMapPopup.vue';
import Footer from '@/components/Footer.vue';
import { useSearchHotels } from '@/hook/useSearchHotels';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'Hotels',
  components: (_components = {
    HeaderComponent: Header,
    PcSearch: PcSearch,
    MobileSearch: MobileSearch,
    Breadcrumb: Breadcrumb,
    FilterContent: FilterContent,
    ActionBarMobile: ActionBarMobile,
    SortingBar: SortingBar,
    HotelCard: HotelCard,
    NoResult: NoResult,
    HotelsMapPopup: HotelsMapPopup,
    FooterComponent: Footer
  }, _defineProperty(_components, _List.name, _List), _defineProperty(_components, "SvgIcon", SvgIcon), _components),
  mounted: function mounted() {
    setTimeout(function () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 100);
  },
  inject: ['isMobile', 't'],
  setup: function setup() {
    var store = useStore();

    var _useHotelOption = useHotelOption(),
        hotelOptionData = _useHotelOption.hotelOptionData;

    var isMapOpen = ref(false);
    var sortBy = computed(function () {
      return store.state.hotels.hotelsSort;
    });
    var hotels = computed(function () {
      return store.getters['hotels/getHotels'];
    });
    var recommend = computed(function () {
      return store.getters['hotels/getRecommend'];
    });
    var currentPage = computed(function () {
      return store.state.hotels.hotelsPagination.current_page;
    });
    var isFinished = computed(function () {
      return store.state.hotels.hotelsPagination.current_page === store.state.hotels.hotelsPagination.total_pages;
    });
    var hotelsLoading = computed(function () {
      return store.state.hotels.hotelsListLoading;
    });

    var _useSearchHotels = useSearchHotels(),
        handleSearchHotels = _useSearchHotels.handleSearchHotels;

    var handleOpenMap = function handleOpenMap(isOpen) {
      isMapOpen.value = isOpen;
    };

    var changeSortBy = function changeSortBy(sort) {
      store.dispatch('hotels/handelHotelsSort', sort);
      handleSearchHotels(1);
    };

    onMounted(function () {
      handleSearchHotels(1);
    });

    var onLoad = function onLoad() {
      handleSearchHotels(currentPage.value + 1);
    };

    var locale = inject('locale');
    watch(locale, function () {
      handleSearchHotels(1);
    });
    return {
      hotelOptionData: hotelOptionData,
      sortBy: sortBy,
      changeSortBy: changeSortBy,
      hotels: hotels,
      recommend: recommend,
      isMapOpen: isMapOpen,
      handleOpenMap: handleOpenMap,
      isFinished: isFinished,
      hotelsLoading: hotelsLoading,
      onLoad: onLoad
    };
  }
});