import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/tabs/style";
import _Tabs from "vant/es/tabs";
import "vant/es/tab/style";
import _Tab from "vant/es/tab";

var _components;

import "core-js/modules/es.function.name.js";
import { defineComponent, inject } from 'vue';
export default defineComponent({
  components: (_components = {}, _defineProperty(_components, _Tab.name, _Tab), _defineProperty(_components, _Tabs.name, _Tabs), _components),
  props: ['sortBy', 'sortingItems', 'changeSortBy'],
  setup: function setup() {
    var t = inject('t');
    return {
      t: t
    };
  }
});