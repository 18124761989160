import _slicedToArray from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/config-provider/style";
import _ConfigProvider from "vant/es/config-provider";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.anchor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import { computed, defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';
import Popup from '@/components/share/Popup.vue';
import HotelsMapCard from '@/components/hotels/HotelsMapCard.vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
import { numberWithCommas } from '@/utils/numberWithCommas';
import { useSearchHotels } from '@/hook/useSearchHotels';
export default defineComponent({
  name: 'HotelsMap',
  components: (_components = {}, _defineProperty(_components, _ConfigProvider.name, _ConfigProvider), _defineProperty(_components, "Popup", Popup), _defineProperty(_components, "HotelsMapCard", HotelsMapCard), _defineProperty(_components, "SvgIcon", SvgIcon), _components),
  props: ['open', 'handleOpenMap'],
  setup: function setup() {
    var gmapRef = ref(null);
    var mapRef = ref(null);
    var popups = ref([]);
    var store = useStore();
    var activeHotelId = ref(null);

    var _useSearchHotels = useSearchHotels(),
        handleGoHotel = _useSearchHotels.handleGoHotel,
        handleSearchHotelsByLocation = _useSearchHotels.handleSearchHotelsByLocation;

    var _MapPopup;

    var themeVars = {
      popupRoundBorderRadius: '8px'
    };
    var hotels = computed(function () {
      return store.getters['hotels/getHotelsByLocation'];
    });
    var getCityLocation = computed(function () {
      return store.getters['options/getCityLocation'];
    });
    var mapCenterCoordinate = computed(function () {
      return store.getters['hotels/getMapCenterCoordinate'];
    });

    var handleHotelActive = function handleHotelActive(id) {
      if (id) {
        onModelOpen(id)();
      } else {
        onAllModelClose();
      }
    };

    var definePopupClass = function definePopupClass() {
      var _this;
      /**
       * A customized popup on the map.
       * @param {!google.maps.LatLng} position
       * @param {!Element} content
       * @constructor
       * @extends {google.maps.OverlayView}
       */


      _MapPopup = function MapPopup(_ref) {
        var position = _ref.position,
            content = _ref.content,
            id = _ref.id,
            hotel = _ref.hotel;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        _this = this;
        _this.position = position;
        content.classList.add('popup-bubble-content');
        var pixelOffset = document.createElement('div');
        pixelOffset.classList.add('popup-bubble-anchor');
        pixelOffset.appendChild(content);
        _this.anchor = document.createElement('div');

        _this.anchor.classList.add('popup-tip-anchor');

        _this.anchor.id = "marker-".concat(id);

        _this.anchor.appendChild(pixelOffset);

        var popupId = "hotel-popup-".concat(id);
        _this.hotelPopup = document.createElement('div');

        _this.hotelPopup.classList.add('popup-tip-anchor');

        _this.hotelPopup.id = popupId;

        _this.hotelPopup.appendChild(cardPopupContent(hotel));

        _this.anchor.addEventListener('mouseenter', function () {
          handleHotelActive(id);
        }); // Optionally stop clicks, etc., from bubbling up to the map.


        _MapPopup.prototype.stopEventPropagation();

        var mapDiv = document.getElementsByClassName('vue-map')[0];
        mapDiv.addEventListener('click', onClickOutside);
      }; // NOTE: google.maps.OverlayView is only defined once the Maps API has
      // loaded. That is why Popup is defined inside initMap().


      _MapPopup.prototype = Object.create(window.google.maps.OverlayView.prototype);
      /** Called when the popup is added to the map. */

      _MapPopup.prototype.onAdd = function () {
        this.getPanes().floatPane.appendChild(_this.anchor);
        this.getPanes().floatPane.appendChild(_this.hotelPopup);
      };
      /** Called when the popup is removed from the map. */


      _MapPopup.prototype.onRemove = function () {
        if (_this.anchor && _this.anchor.parentElement) {
          _this.anchor.parentElement.removeChild(_this.anchor);

          _this.anchor.removeEventListener('click', onModelOpen);
        }

        if (_this.hotelPopup && _this.hotelPopup.parentElement) {
          _this.hotelPopup.parentElement.removeChild(_this.hotelPopup);
        }
      };
      /** Called when the popup needs to draw itself. */


      _MapPopup.prototype.draw = function () {
        var divPosition = this.getProjection().fromLatLngToDivPixel(_this.position); // Hide the popup when it is far out of view.

        var display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

        if (_this.anchor && display === 'block') {
          _this.anchor.style.left = divPosition.x + 'px';
          _this.anchor.style.top = divPosition.y + 'px';
          _this.hotelPopup.style.left = divPosition.x - 140 + 'px';
          _this.hotelPopup.style.top = divPosition.y - 140 + 'px';
        }

        if (_this.anchor && _this.anchor.style.display !== display) {
          _this.anchor.style.display = display;
          _this.hotelPopup.style.display = display;
        }
      };
      /** Stops clicks/drags from bubbling up to the map. */


      _MapPopup.prototype.stopEventPropagation = function () {
        var anchor = _this.anchor;
        anchor.style.cursor = 'auto';
        ['click', 'dblclick', 'contextmenu', 'wheel', 'mousedown', 'touchstart', 'pointerdown'].forEach(function (event) {
          anchor.addEventListener(event, function (e) {
            e.stopPropagation();
          });
        });
        var hotelPopup = _this.hotelPopup;
        hotelPopup.style.cursor = 'auto';
        ['click', 'dblclick', 'contextmenu', 'wheel', 'mousedown', 'touchstart', 'pointerdown'].forEach(function (event) {
          hotelPopup.addEventListener(event, function (e) {
            e.stopPropagation();
          });
        });
      };

      var onClickOutside = function onClickOutside(e) {
        e.stopPropagation();
        onAllModelClose();
      };
    };

    var generateMapPins = function generateMapPins(map, hotels) {
      hotels.forEach(function (hotel) {
        definePopupClass();
        var el = document.createElement('div');
        var coord = new window.google.maps.LatLng(hotel.lat, hotel.lng);
        el.innerHTML = htmlGenerator(hotel) || '';
        var popup = new _MapPopup({
          position: coord,
          content: el,
          id: hotel.hotel_id,
          hotel: hotel
        });
        popup.setMap(map);
        popups.value.push(popup);
      });
    };

    var removeMapPins = function removeMapPins() {
      popups.value.forEach(function (popup) {
        popup.setMap(null);
      });
    };

    var htmlGenerator = function htmlGenerator(hotel) {
      return "NT$ ".concat(hotel.pms_lowest_price);
    };

    var cardPopupContent = function cardPopupContent(hotel) {
      var hotelPopup = document.createElement('div');
      hotelPopup.className = 'popup-card';
      hotelPopup.style.visibility = 'hidden';
      var imgDiv = document.createElement('div');
      imgDiv.className = 'img';
      imgDiv.style.backgroundImage = "url('".concat(hotel.image, "')");
      var contentDiv = document.createElement('div');
      contentDiv.className = 'content-section';
      contentDiv.innerHTML = "\n        <div>\n          <div class=\"title\">".concat(hotel.name, "</div>\n          <div class=\"score-section\">\n            <div class=\"score\">").concat(hotel.ranking, "</div>\n            <div class=\"info\">").concat(hotel.reviews, "\u5247\u8A55\u8AD6</div>\n          </div>\n        </div>\n        <div class=\"price-section\">\n          <div>\n            <div class=\"org-price\">NT$ ").concat(numberWithCommas(hotel.original_price || ''), "</div>\n            <div class=\"price\">NT$ ").concat(numberWithCommas(hotel.lowest_price || ''), "</div>\n          </div>\n        </div>\n      ");
      hotelPopup.appendChild(imgDiv);
      hotelPopup.appendChild(contentDiv);
      return hotelPopup;
    };

    var redirectToHotelPage = function redirectToHotelPage(id) {
      handleGoHotel(id);
    };

    var onModelOpen = function onModelOpen(id) {
      return function () {
        var popupId = "hotel-popup-".concat(id);
        var anchorEl = document.querySelector("#marker-".concat(id, " .popup-bubble-anchor"));
        var popupEl = document.getElementById(popupId);
        var currentPopup = document.querySelector("#".concat(popupId, " .popup-card"));
        onModelClose(activeHotelId.value);

        if ((currentPopup === null || currentPopup === void 0 ? void 0 : currentPopup.style.visibility) === 'hidden') {
          if (popupEl !== null && popupEl !== void 0 && popupEl.style) {
            popupEl.style.zIndex = '1';
          }

          anchorEl.classList.add('active');
          currentPopup.style.visibility = 'visible';
          currentPopup.addEventListener('click', function () {
            return redirectToHotelPage(id);
          });
        } else if (currentPopup !== null && currentPopup !== void 0 && currentPopup.style) {
          if (popupEl !== null && popupEl !== void 0 && popupEl.style) {
            popupEl.style.zIndex = '';
          }

          anchorEl.classList.remove('active');
          currentPopup.style.visibility = 'hidden';
          currentPopup.removeEventListener('click', function () {
            return redirectToHotelPage(id);
          });
        }

        activeHotelId.value = id;
      };
    };

    var onModelClose = function onModelClose(id) {
      if (!id) return;
      var popupId = "hotel-popup-".concat(id);
      var anchorEl = document.querySelector("#marker-".concat(id, " .popup-bubble-anchor"));
      var popupEl = document.getElementById(popupId);
      var currentPopup = document.querySelector("#".concat(popupId, " .popup-card"));

      if (popupEl !== null && popupEl !== void 0 && popupEl.style) {
        popupEl.style.zIndex = '';
      }

      anchorEl.classList.remove('active');
      currentPopup.style.visibility = 'hidden';
    };

    var onAllModelClose = function onAllModelClose() {
      var allAnchor = document.getElementsByClassName('popup-bubble-anchor');
      Array.from(allAnchor).forEach(function (popups) {
        popups.classList.remove('active');
      });
      var allPopups = document.getElementsByClassName('popup-card');
      Array.from(allPopups).forEach(function (popups) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        popups.style.visibility = 'hidden';
      });
    };

    var CenterControl = function CenterControl(controlDiv, map) {
      controlDiv.style.visibility = 'hidden';
      var controlUI = document.createElement('div');
      controlUI.style.backgroundColor = '#fff';
      controlUI.style.border = '2px solid #1983CF';
      controlUI.style.borderRadius = '100px';
      controlUI.style.cursor = 'pointer';
      controlUI.style.marginTop = '30px';
      controlUI.style.textAlign = 'center';
      controlDiv.appendChild(controlUI);
      var controlText = document.createElement('div');
      controlText.style.color = '#1983CF';
      controlText.style.fontSize = '16px';
      controlText.style.lineHeight = '22px';
      controlText.style.fontWeight = '500';
      controlText.style.padding = '11px 32px';
      controlText.innerHTML = '重新搜尋此區域';
      controlUI.appendChild(controlText);
      controlUI.addEventListener('click', function (e) {
        e.stopPropagation(); // MapPopup.setMap(null)

        removeMapPins();
        handleSearchHotelsByLocation({
          lat: map.getCenter().lat(),
          lng: map.getCenter().lng()
        });
      });
    };

    watch([hotels, mapRef], function (_ref2, _ref3) {
      var _ref4 = _slicedToArray(_ref2, 2),
          hotelsValue = _ref4[0],
          mapRefValue = _ref4[1];

      var _ref5 = _slicedToArray(_ref3, 2),
          prevHotelsValue = _ref5[0],
          prevMapRefValue = _ref5[1];

      if (hotelsValue !== prevHotelsValue) {
        if (mapCenterCoordinate.value.lat) {
          mapRefValue.setCenter({
            lat: mapCenterCoordinate.value.lat,
            lng: mapCenterCoordinate.value.lng
          });
        }

        if (mapRefValue) {
          handleHotelActive(hotelsValue[0].hotel_id);
        }
      }

      if (mapRefValue && mapRefValue.controls) {
        generateMapPins(mapRefValue, hotelsValue);

        if (!prevMapRefValue && mapRefValue) {
          var centerControlDiv = document.createElement('div');
          CenterControl(centerControlDiv, mapRefValue);
          mapRefValue.controls[window.google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);
        }
      }
    });
    watch([gmapRef], function (_ref6) {
      var _ref7 = _slicedToArray(_ref6, 1),
          googleMap = _ref7[0];

      if (googleMap && gmapRef) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        googleMap.$mapPromise.then(function (map) {
          mapRef.value = map;
        });
      }
    });
    return {
      themeVars: themeVars,
      getCityLocation: getCityLocation,
      hotels: hotels,
      gmapRef: gmapRef,
      activeHotelId: activeHotelId,
      handleHotelActive: handleHotelActive
    };
  }
});