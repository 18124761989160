import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/button/style";
import _Button from "vant/es/button";

var _components;

import "core-js/modules/es.function.name.js";
import { computed, defineComponent, inject, ref } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
import Popup from '@/components/share/Popup.vue';
import Page from '@/components/share/Page.vue';
import NavBar from '@/components/share/NavBar.vue';
import ListMenu from '@/components/share/ListMenu.vue';
import ListItem from '@/components/share/ListItem.vue';
import FilterContent from '@/components/hotels/FilterContent.vue';
import { useSearchHotels } from '@/hook/useSearchHotels';
import { useFilterTags } from '@/hook/useFilterTags';
import HotelsMapPopup from '@/components/hotels/HotelsMapPopup.vue';
export default defineComponent({
  components: (_components = {}, _defineProperty(_components, _Button.name, _Button), _defineProperty(_components, _Cell.name, _Cell), _defineProperty(_components, "SvgIcon", SvgIcon), _defineProperty(_components, "Popup", Popup), _defineProperty(_components, "Page", Page), _defineProperty(_components, "NavBar", NavBar), _defineProperty(_components, "ListMenu", ListMenu), _defineProperty(_components, "ListItem", ListItem), _defineProperty(_components, "FilterContent", FilterContent), _defineProperty(_components, "HotelsMapPopup", HotelsMapPopup), _components),
  props: ['sortBy', 'sortingItems', 'changeSortBy'],
  setup: function setup(props) {
    var t = inject('t');

    var _useSearchHotels = useSearchHotels(),
        handleGoHotels = _useSearchHotels.handleGoHotels;

    var _useFilterTags = useFilterTags(),
        roomFacilities = _useFilterTags.roomFacilities,
        hotelFacilities = _useFilterTags.hotelFacilities,
        range = _useFilterTags.range,
        handleAllClear = _useFilterTags.handleAllClear;

    var isFilterModalOpen = ref(false);
    var isSortingModalOpen = ref(false);
    var isMapOpen = ref(false);
    var filterCounts = computed(function () {
      var priceFilterCounts = range.value[0] !== 0 || range.value[1] !== 100 ? 1 : 0;
      return hotelFacilities.value.length + roomFacilities.value.length + priceFilterCounts;
    });

    var openFilterModal = function openFilterModal() {
      isFilterModalOpen.value = true;
    };

    var closeFilterModal = function closeFilterModal() {
      handleGoHotels();
      isFilterModalOpen.value = false;
    };

    var openSortingModal = function openSortingModal() {
      isSortingModalOpen.value = true;
    };

    var closeSortingModal = function closeSortingModal() {
      isSortingModalOpen.value = false;
    };

    var handleSortBy = function handleSortBy(name) {
      props.changeSortBy(name);
      closeSortingModal();
    };

    var handleOpenMap = function handleOpenMap(isOpen) {
      isMapOpen.value = isOpen;
    };

    return {
      t: t,
      isFilterModalOpen: isFilterModalOpen,
      openFilterModal: openFilterModal,
      closeFilterModal: closeFilterModal,
      isSortingModalOpen: isSortingModalOpen,
      openSortingModal: openSortingModal,
      closeSortingModal: closeSortingModal,
      handleAllClear: handleAllClear,
      handleSortBy: handleSortBy,
      isMapOpen: isMapOpen,
      handleOpenMap: handleOpenMap,
      filterCounts: filterCounts
    };
  }
});