import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { computed, defineComponent, inject } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
import { useStore } from 'vuex';
import { useFilterTags } from '@/hook/useFilterTags';
import { useHotelOption } from '@/hook/useHotelOption';
import { useSearchHotels } from '@/hook/useSearchHotels';
export default defineComponent({
  components: {
    SvgIcon: SvgIcon
  },
  setup: function setup() {
    var t = inject('t');
    var store = useStore();

    var searchAgain = function searchAgain() {
      store.dispatch('form/setSearchBar', 'location');
      store.dispatch('form/setIsExpand', true);
    };

    var _useFilterTags = useFilterTags(),
        roomFacilities = _useFilterTags.roomFacilities,
        hotelFacilities = _useFilterTags.hotelFacilities;

    var _useHotelOption = useHotelOption(),
        hotelOptionData = _useHotelOption.hotelOptionData;

    var roomTags = computed(function () {
      var _hotelOptionData$valu;

      return (_hotelOptionData$valu = hotelOptionData.value.room_facilities) === null || _hotelOptionData$valu === void 0 ? void 0 : _hotelOptionData$valu.filter(function (tag) {
        return roomFacilities.value.some(function (i) {
          return i === tag.lang_key;
        });
      });
    });
    var hotelTags = computed(function () {
      var _hotelOptionData$valu2;

      return (_hotelOptionData$valu2 = hotelOptionData.value.hotel_facilities) === null || _hotelOptionData$valu2 === void 0 ? void 0 : _hotelOptionData$valu2.filter(function (tag) {
        return hotelFacilities.value.some(function (i) {
          return i === tag.lang_key;
        });
      });
    });

    var _useSearchHotels = useSearchHotels(),
        handleGoHotels = _useSearchHotels.handleGoHotels;

    var clearFilterAndSearch = function clearFilterAndSearch() {
      store.dispatch('form/setSearchCondition', {
        field: 'hotel_facility',
        value: []
      });
      store.dispatch('form/setSearchCondition', {
        field: 'room_facility',
        value: []
      });
      handleGoHotels();
    };

    return {
      t: t,
      searchAgain: searchAgain,
      roomTags: roomTags,
      hotelTags: hotelTags,
      clearFilterAndSearch: clearFilterAndSearch
    };
  }
});