import { computed, Ref } from 'vue'
import { useStore } from 'vuex'
export interface useFilterTagsType {
  roomFacilities: Ref<string[]>
  hotelFacilities: Ref<string[]>
  range: Ref<number[]>
  popular: Ref<string[]>
  onClearRange: () => void
  onClearRoomFacilities: () => void
  onClearHotelFacilities: () => void
  onClearPopular: () => void
  handleAllClear: () => void
}
export const useFilterTags = (): useFilterTagsType => {
  const store = useStore()
  const search = computed(() => store.state.form.search)

  const popular = computed({
    get: () => search.value.filter,
    set: (val) => {
      store.dispatch('form/setSearchCondition', { field: 'filter', value: val })
    }
  })

  const roomFacilities = computed({
    get: () => search.value.room_facility,
    set: (val) => {
      store.dispatch('form/setSearchCondition', { field: 'room_facility', value: val })
    }
  })

  const hotelFacilities = computed({
    get: () => search.value.hotel_facility,
    set: (val) => {
      store.dispatch('form/setSearchCondition', { field: 'hotel_facility', value: val })
    }
  })
  const range = computed({
    get: () => [Number(search.value.lowest_price) / 100, Number(search.value.highest_price) / 100],
    set: (val) => {
      store.dispatch('form/setSearchCondition', { field: 'lowest_price', value: val[0] * 100 })
      store.dispatch('form/setSearchCondition', { field: 'highest_price', value: val[1] * 100 })
    }
  })

  const onClearRange = () => {
    range.value = [0, 100]
  }

  const onClearPopular = () => {
    popular.value = []
  }
  const onClearRoomFacilities = () => {
    roomFacilities.value = []
  }
  const onClearHotelFacilities = () => {
    hotelFacilities.value = []
  }

  const handleAllClear = () => {
    onClearRange()
    onClearRoomFacilities()
    onClearHotelFacilities()
  }

  return {
    roomFacilities,
    hotelFacilities,
    popular,
    range,
    onClearRange,
    onClearRoomFacilities,
    onClearHotelFacilities,
    onClearPopular,
    handleAllClear
  }
}
