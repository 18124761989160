import "core-js/modules/es.function.name.js";
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-33a5733f"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "content-section"
};
var _hoisted_2 = {
  class: "title"
};
var _hoisted_3 = {
  class: "score-section"
};
var _hoisted_4 = {
  class: "score"
};
var _hoisted_5 = {
  class: "info"
};
var _hoisted_6 = {
  class: "price-section"
};
var _hoisted_7 = {
  class: "info"
};
var _hoisted_8 = {
  class: "org-price"
};
var _hoisted_9 = {
  class: "price"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "card",
    onMouseenter: _cache[0] || (_cache[0] = _withModifiers(function () {
      return _ctx.hoverHotelCard(_ctx.hotel.hotel_id);
    }, ["stop"])),
    onMouseleave: _cache[1] || (_cache[1] = _withModifiers(function () {
      return _ctx.hoverHotelCard(null);
    }, ["stop"])),
    onClick: _cache[2] || (_cache[2] = _withModifiers(function () {
      return _ctx.redirectToHotelPage(_ctx.hotel.hotel_id);
    }, ["stop"]))
  }, [_createElementVNode("div", {
    class: "img",
    style: _normalizeStyle({
      'background-image': 'url(' + _ctx.hotel.image + ')'
    })
  }, null, 4), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.hotel.name), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.hotel.ranking), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('search.n_reviews', {
    n: _ctx.hotel.reviews
  })), 1)])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('search.per_night_price')), 1), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_8, "NT$ " + _toDisplayString(_ctx.numberWithCommas(_ctx.hotel.original_price)), 1), _createElementVNode("div", _hoisted_9, "NT$ " + _toDisplayString(_ctx.numberWithCommas(_ctx.hotel.pms_lowest_price)), 1)])])])], 32);
}