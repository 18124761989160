import "core-js/modules/es.array.sort.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2408245e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "tab-bar-section"
};
var _hoisted_2 = {
  class: "tabs"
};
var _hoisted_3 = ["value", "onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortingItems, function (_ref) {
    var sort = _ref.sort;
    return _openBlock(), _createElementBlock("button", {
      class: _normalizeClass(["tab-item", {
        'tab-item--active': _ctx.sortBy === sort
      }]),
      key: sort,
      value: sort,
      onClick: function onClick() {
        return _ctx.changeSortBy(sort);
      }
    }, _toDisplayString(_ctx.t("search.sorting.".concat(sort))), 11, _hoisted_3);
  }), 128))])]);
}