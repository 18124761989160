import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import { computed, defineComponent, inject } from 'vue';
import { useSearchHotels } from '@/hook/useSearchHotels';
import qs from 'qs';
import { useAuthStore } from '@/hook/useAuthStore';
export default defineComponent({
  props: {
    hotel: {
      type: Object
    }
  },
  setup: function setup(props) {
    var isLoggedIn = useAuthStore().isLoggedIn;
    var t = inject('t');
    var locale = inject('locale');

    var _useSearchHotels = useSearchHotels(),
        handleGoHotel = _useSearchHotels.handleGoHotel,
        query = _useSearchHotels.query;

    var businessType = computed(function () {
      var _props$hotel;

      return (_props$hotel = props.hotel) === null || _props$hotel === void 0 ? void 0 : _props$hotel.business_type;
    });
    var acceptSelfHealth = computed(function () {
      var _props$hotel2;

      return (_props$hotel2 = props.hotel) === null || _props$hotel2 === void 0 ? void 0 : _props$hotel2.accept_self_health;
    });
    var features = computed(function () {
      var _props$hotel3;

      return (_props$hotel3 = props.hotel) !== null && _props$hotel3 !== void 0 && _props$hotel3.features ? Object.values(props.hotel.features).filter(function (val) {
        return val;
      }) : [];
    });
    return {
      qs: qs,
      t: t,
      isLoggedIn: isLoggedIn,
      handleGoHotel: handleGoHotel,
      businessType: businessType,
      features: features,
      acceptSelfHealth: acceptSelfHealth,
      query: query,
      locale: locale
    };
  }
});