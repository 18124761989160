import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3217a4cd"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "no-result-section"
};
var _hoisted_2 = {
  key: 0,
  class: "no-result"
};
var _hoisted_3 = {
  key: 1,
  class: "no-result"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  return _openBlock(), _createElementBlock("section", _hoisted_1, [_ctx.roomTags && _ctx.roomTags.length != 0 || _ctx.hotelTags && _ctx.hotelTags.length != 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_svg_icon, {
    iconName: "image_no_result",
    class: "no-result-icon"
  }), _createElementVNode("div", null, _toDisplayString(_ctx.t('loading.sorry_we_don_t_have_available_rooms_fit_your_filter_criteria_please_reset_the_filter_criteria')), 1), _createElementVNode("button", {
    class: "action-btn",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.clearFilterAndSearch();
    })
  }, [_createVNode(_component_svg_icon, {
    iconName: "search",
    class: "icon"
  }), _createTextVNode(" " + _toDisplayString(_ctx.t('loading.clear_filter_search_again')), 1)])])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_svg_icon, {
    iconName: "image_no_result",
    class: "no-result-icon"
  }), _createElementVNode("div", null, _toDisplayString(_ctx.t('loading.sorry_we_don_t_have_available_rooms_fit_your_search_criteria_please_reset_the_search_criteria')), 1), _createElementVNode("button", {
    class: "action-btn",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.searchAgain();
    })
  }, [_createVNode(_component_svg_icon, {
    iconName: "search",
    class: "icon"
  }), _createTextVNode(" " + _toDisplayString(_ctx.t('loading.search_again')), 1)])]))]);
}